<script setup>
    const appId = useRuntimeConfig().public.appId;
</script>

<template>
	<div class="bg-white shadow w-full py-4">
		<div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div class=" flex justify-between items-center">
						<div class="flex">
								<NuxtLink :to="localePath({ name: 'home' })" class="flex"><img class="w-auto lg:h-5 sm:h-3" :src="`/images/${appId}/logo-blue.webp`" alt="Vartur"></NuxtLink>
						</div>
						<div>
								<div class="flex items-center">
										<a href="/" class="flex">
												<img class="w-auto lg:h-10 sm:h-2" :src="`/images/${appId}/logo-blogs.svg`" alt="Vartur">
										</a>
								</div>
						</div>
						<nav class="flex">
								<a href="/life-in-turkey"
										class="text-neutral-600 hover:text-indigo-900 py-2 pe-3">Life in Turkey</a>
								<a href="/life-in-dubai"
										class="text-neutral-600 hover:text-indigo-900 py-2 pe-3">Life in Dubai</a>
						</nav>
				</div>
		</div>
	</div>
</template>
